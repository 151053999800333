<template>
  <b-card
    id="cardo"
    title="Ganadores"
  >
    <div
      v-if="toExport.length > 0"
      id="export-button"
    >
      <json-excel :data="toExport">
        <feather-icon
          icon="SaveIcon"
          size="40"
          class="ml-2 text-success pointer"
        />
      </json-excel>
    </div>
    <b-table
      :items="datatable"
      :fields="fields"
      striped
      responsive
      class="data-table"
    >
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        />
      </template>

      <template #cell(actions)="data">
        <b-link
          v-if="checkContains('details') == true"
          :href="'/client-single/'+ data.item.id"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50 text-primary"
          />
        </b-link>
        <b-link
          v-if="checkContains('master-details') == true"
          :href="'/masters/client-single/'+ enc(data.item.id)"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50 text-primary"
          />
        </b-link>
        <feather-icon
          v-if="checkContains('remove-relation') == true"
          icon="TrashIcon"
          class="mr-50 text-warning pointer"
          @click="removeRelation(relationId(data.item))"
        />
        <feather-icon
          v-if="checkContains('add-relation') == true"
          icon="PlusIcon"
          class="ml-2 text-success pointer"
          @click="addRelation(data.item)"
        />
      </template>
      <!-- <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{status[0][data.value]}}
        </b-badge>
      </template> -->
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge, BCardText, BLink,
} from 'bootstrap-vue'
import JsonExcel from 'vue-json-excel'

import gql from 'graphql-tag'
import { _ } from 'vue-underscore'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    JsonExcel,
  },
  props: ['roundId', 'contextActions', 'addInfo', 'customFields', 'pageInfo'],
  data() {
    return {
      datatable: null,
      fields: ['id', 'bet_id',
        { key: 'player.casino.name', label: 'casino' },
        { key: 'player.last_nickname', label: 'username' },
        { key: 'player.id', label: 'playerId' },
        { key: 'player.casino_player_id', label: 'id usuario en casino' },
        { key: 'created_at', label: 'cuando' },
        { key: 'win_value', label: 'ganancia' },

      ],
      data: null,
      toExport: [],
    }
  },
  apollo: {
    $subscribe: {

      datatable: {
        query: gql`
            subscription round_winners($roundId: Int!){
        round_winners(where:{round_id:{_eq:$roundId}} ){
            id
            bet_id
            player{
              id
              last_nickname
              casino{
                name
              }
              casino_player_id
            }
            created_at
            win_value
        }
      }
      
      `,
        client: 'rouletteClient',
        variables() {
          return {
            roundId: this.roundId,
          }
        },
        result({ data }) {
          this.datatable = data.round_winners
        },
      },
    },
  },
  watch: {
    search() {
      this.data = this.data
      _.each(this.search, this.formatSave)
    },

  },
  created() {
  },
  methods: {
    formatSave(e) {
      const toSave = {
        bet_id: e.bet_id,
        casino: e.player.casino.name,
        username: e.last_nickname,
        playerId: e.player.id,
        id_player_casino: e.player.casino_player_id,
        created_at: e.created_at,
        win_value: e.win_value,
      }
      this.toExport.push(toSave)
    },
    checkContains(index) {
      return _.contains(this.contextActions, index)
    },
    dateFormatter(value) {
      return this.$datter.convert(value)
    },
    enc(id) {
      return btoa(id)
    },

  },

}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  #export-button{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #cardo{
    position:relative
  }

</style>
