<template>
  <div class="body-content-overlay2 show">
    <section class="chat-app-window">

      <div class="active-chat">
        <b-row>
          <b-col cols="3">
            <div class="sidebar-left">
              <div class="sidebar">
                <div class="sidebar-content card">
                  <section class="ps-container chat-user-list-wrapper list-group scroll-area ps ps--active-y">
                    <h4 class="chat-list-title">
                      Usuarios <small>(Total: {{ user_list.length }})</small>
                    </h4>
                    <ul class="chat-users-list chat-list media-list">
                      <li
                        v-for="user in user_list"
                        :key="user.user_id"
                      >
                        <b-avatar
                          size="42"
                          :badge="true"
                          class="badge-minimal"
                          :badge-variant="primary"
                          variant="transparent"
                        />

                        <div class="chat-info flex-grow-1">
                          <h5 class="mb-0"> {{ user.user.last_nickname }}
                          </h5>
                          <p class="card-text text-truncate">
                            {{ user.user.casino.name }}

                          </p>
                        </div>
                        <div class="chat-meta text-nowrap">
                          <small class="float-right mb-25 chat-time" />
                          <!---->
                        </div>
                      </li>
                    </ul>

                  </section>
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="content-right">
              <div class="content-wrapper">
                <div class="content-body">
                  <section class="chat-app-window">
                    <div class="active-chat">

                      <section class="ps-container user-chats scroll-area ps">
                        <div class="chats">
                          <div
                            v-for="msgData in chat_last_messages"
                            :key="msgData.created_at"
                            class="chat"
                            :class="{ 'chat-left': msgData.player_id != playerId }"
                          >
                            <div class="chat-avatar">
                              <b-avatar
                                size="36"
                                class="avatar-border-2 box-shadow-1"
                                variant="transparent"
                              />
                            </div>
                            <div class="chat-body">
                              <div class="chat-content">
                                <p>
                                  {{ msgData.message }}
                                  ({{ msgData.player.last_nickname }})

                                  <b-button
                                    v-if="msgData.status != 'REMOVED'"
                                    size="sm"
                                    variant="outline-primary"
                                    class="btn-tour-skip mr-1"
                                    @click="remove_message(msgData.id)"
                                  >
                                    <span class="mr-25 align-middle">Borrar</span>
                                    <feather-icon
                                      icon="NextIcon"
                                      size="12"
                                    />
                                  </b-button>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <!-- Message Input -->
                      <b-form
                        class="chat-app-form"
                        @submit.prevent="sendMessage"
                      >
                        <b-input-group class="input-group-merge form-send-message mr-1">
                          <b-form-input
                            v-model="message"
                            placeholder="Enter your message"
                          />
                        </b-input-group>
                        <b-button
                          variant="primary"
                          type="submit"
                          @click="send_message(message)"
                        >
                          Send
                        </b-button>
                      </b-form>
                    </div>

                  </section>

                </div>

              </div>

            </div>
          </b-col>
        </b-row>

        <div />
      </div>
    </section>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab, BButton, BCardText, BLink, BFormInput, BCol, BRow, BModal, BForm, BInputGroup,
  BBadge, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import WinnerList from '@/components/roulette/WinnerList.vue'
import BetList from '@/components/roulette/BetList.vue'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'

export default {
  components: {
    BButton,
    BBadge,
    BAvatar,
    BTab,
    BForm,
    VuePerfectScrollbar,
    BFormInput,
    BInputGroup,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BCol,
    WinnerList,
    BetList,
    BRow,
    BTabs,
    BModal,
    BLink,
    underscore: _,
    LogoLoaderAnimation,
  },
  data() {
    return {
      roundId: this.$route.params.roundId,
      chat_last_messages: [],
      message: '',
      user_list: [],
      playerId: null,
      chatmaxMessages: 100,
    }
  },
  apollo: {
    $subscribe: {

      users: {
        query: gql`
      subscription chat_user_online($roomId:uuid){
  chat_user_online(where:{room_id:{_eq: $roomId}}){
    room_id
    user_id
    user{
      last_nickname
      casino{
        name
      }
    }
  }
}

`,
        client: 'chatClient',
        variables() {
          return {
            roomId: this.$route.params.roomId,
          }
        },
        result({ data }) {
          this.user_list = data.chat_user_online
        },
      },
      chat_last_messages: {
        query: gql`
      

      subscription chat_last_messages_stream($roomId: uuid){
                          chat_last_messages_stream(batch_size:20, cursor:{initial_value: {id: 1}},where:{room_id: {_eq:$roomId}, _and:{status:{_eq:"ACEPTED"}}}){
                              id
                              message
                              player_id
                              player{
                                  casino_id
                                  casino{
                                      name
                                  }
                                  casino_player_id
                                  last_nickname
                              }
                              created_at
                              status
                          }
                      }

`,
        client: 'chatClient',
        variables() {
          return {
            roomId: this.$route.params.roomId,
          }
        },
        result({ data }) {
          if (this.chat_last_messages.length == 0) { this.chat_last_messages = data.chat_last_messages_stream } else {
            this.chat_last_messages = _.union(this.chat_last_messages, data.chat_last_messages_stream)
          }
        },
      },
    },
  },
  watch: {

  },
  mounted() {
    this.login()
  },
  methods: {
    async login() {
      // Todo: login on chat as moderator
      await this.insert_or_get()
      // .....

      this.keep_live_timer()
    },
    keep_live_timer() {
      this.keep_live()

      setTimeout(() => {
        this.keep_live_timer()
      }, 25000)
    },

    get_player() {
      const user = window.localStorage.getItem('userData')
      return JSON.parse(user)
    },

    remove_message(id) {
      return this.$apollo.mutate({ /**/
        mutation: gql`



mutation remove_message($id:Int!){
  update_chat_messages_by_pk(_set:{status_id: 2}, pk_columns:{id: $id}){
    id
    status_id
  }
}
          `,
        variables: {
          id,
        },
        client: 'chatClient',
      }).then(data => {
        // Result
        this.chat_last_messages = this.chat_last_messages.filter(item => item.id != id)
      }).catch(error => {
        console.error(error)
      })
    },
    insert_or_get() {
      return this.$apollo.mutate({ /**/
        mutation: gql`



mutation insert_moderator($casino_player_id:String, $last_nickname:String){
  insert_players_one(object:{casino_id:3, casino_player_id:$casino_player_id, last_nickname:$last_nickname},
  on_conflict:{update_columns:updated_at, constraint:players_casino_id_casino_player_id_key}){
    id
  }
}
          `,
        variables: {
          roomId: this.$route.params.roomId,
          casino_player_id: this.get_player().uid.toString(),
          last_nickname: this.get_player().username,
          //              offset : this.pageToSearch,
          //            status: this.filterStatus
        },
        client: 'chatClient',
      }).then(data => {
        // Result
        this.playerId = data.data.insert_players_one.id
      }).catch(error => {
        console.error(error)
      })
    },
    keep_live() {
      return this.$apollo.mutate({ /**/
        mutation: gql`
            mutation insert_chat_user_room($roomId: uuid, $playerId: Int!) {
            insert_chat_user_room(objects: [{room_id: $roomId, user_id: $playerId}], on_conflict: {update_columns: updated_at, constraint: user_room_room_id_user_id_key}) {
                affected_rows
            }
            }

          `,
        variables: {
          roomId: this.$route.params.roomId,
          playerId: this.playerId,
          //              offset : this.pageToSearch,
          //            status: this.filterStatus
        },
        client: 'chatClient',
      }).then(data => {
        // Result
        console.log(data.data.insert_chat_user_room)
      }).catch(error => {
        console.error(error)
      })
    },
    send_message(message) {
      this.$apollo.mutate({ /**/
        mutation: gql`
            mutation insert_chat_messages_one($message: String!, $roomId: uuid!, $playerId: Int!){
              insert_chat_messages_one(object:{message: $message, room_id: $roomId, player_id: $playerId}){
                id
                created_at
              }

              
            }
          `,
        variables: {
          message,
          roomId: this.$route.params.roomId,
          playerId: this.playerId,
          //              offset : this.pageToSearch,
          //            status: this.filterStatus
        },
        client: 'chatClient',
      }).then(data => {
        // Result
        console.log(data.data.insert_chat_messages_one)
        this.keep_live()
      }).catch(error => {
        console.error(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
