<template>
  <b-card
    id="cardo"
    title="Apuestas"
  >
    <div
      v-if="toExport.length > 0"
      id="export-button"
    >
      <json-excel :data="toExport">
        <feather-icon
          icon="SaveIcon"
          size="40"
          class="ml-2 text-success pointer"
        />
      </json-excel>
    </div>
    <b-table
      :items="datatable_bets"
      :fields="fields"
      striped
      responsive
      class="data-table"
    >
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        />
      </template>

    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge, BCardText, BLink,
} from 'bootstrap-vue'
import JsonExcel from 'vue-json-excel'

import gql from 'graphql-tag'
import { _ } from 'vue-underscore'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    JsonExcel,
  },
  props: ['roundId', 'contextActions', 'addInfo', 'customFields', 'pageInfo'],
  data() {
    return {
      datatable_bets: null,
      fields: ['id',
        { key: 'amount', label: 'valor' },
        // { key: "numbers", label: "numeros"},

        { key: 'player.casino.name', label: 'casino' },
        { key: 'player.last_nickname', label: 'username' },
        { key: 'player.id', label: 'playerId' },
        { key: 'player.casino_player_id', label: 'id usuario en casino' },
        { key: 'created_at', label: 'cuando' },
        { key: 'updated_at', label: 'modificada' },
        { key: 'session_id', label: 'session' },
        { key: 'status', label: 'estado' },
        { key: 'round_winners.win_value', label: 'ganancia' },

      ],
      data: null,
      toExport: [],
    }
  },
  apollo: {
    $subscribe: {

      datatable_bets: {
        query: gql`
subscription bets($id: Int!) {
  bets(where: {round_id: {_eq: $id}}) {
    id
    amount
    created_at
    numbers {
      number
    }
    player {
      id
      casino_player_id
      casino {
        id
        code
        name
      }
      last_nickname
    }
    session_id
    status
    updated_at
    round_winners {
      id
      win_value
    }
  }
}

      `,
        client: 'chatClient',
        variables() {
          return {
            id: this.roundId,
          }
        },
        result({ data }) {
          this.datatable_bets = data.bets
        },
      },
    },
  },
  watch: {
    search() {
      this.data = this.data
      _.each(this.search, this.formatSave)
    },

  },
  created() {
  },
  methods: {
    formatSave(e) {
      const toSave = {
        bet_id: e.bet_id,
        casino: e.player.casino.name,
        username: e.last_nickname,
        playerId: e.player.id,
        id_player_casino: e.player.casino_player_id,
        created_at: e.created_at,
        win_value: e.win_value,
      }
      this.toExport.push(toSave)
    },
    checkContains(index) {
      return _.contains(this.contextActions, index)
    },
    dateFormatter(value) {
      return this.$datter.convert(value)
    },
    enc(id) {
      return btoa(id)
    },

  },

}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  #export-button{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #cardo{
    position:relative
  }

</style>
